<template>
  <div>
    <el-col :span="24" class="change-rw">
      <el-button
        class="btn-change"
        size="medium"
        v-if="!isSupplier"
        :class="isRecharge ? 'btn-black' : 'btn-gray'"
        @click="tablePageCP = 1;getDataList(1, 'true');"
      >
        {{ $t('btnI18n.Deposit') }}
        <i
          class="tips-count-num el-icon--right"
        >{{ rechargeNotApproveNum || 0 }}</i>
      </el-button>
      <el-button
        class="btn-change"
        size="medium"
        :class="isRecharge ? 'btn-gray' : 'btn-black'"
        @click="tablePageCP = 1;getDataList(2, 'false');"
      >
        {{ $t('btnI18n.Withdrawal') }}
        <i
          class="tips-count-num el-icon--right"
        >{{ withdrawNotApproveNum || 0 }}</i>
      </el-button>
      <!--      <el-select class="search-select" v-model="selectProcess" placeholder="请选择" @change="getDataList">
        <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>-->
    </el-col>
    <el-table class="table-data" style="width: 100%" :data="dataList">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column
        :label="$t('tableI18n.Proposer')"
        prop="user.username"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        :label="$t('tableI18n.Address')"
        prop="user.address"
        align="center"
        :show-overflow-tooltip="true"
        width="120"
      ></el-table-column>
      <el-table-column
        :label="$t('tableI18n.DateofProposal')"
        prop="createdAt"
        align="center"
        width="110"
      >
        <template v-slot="scope">{{ scope.row.createdAt | timeStampToDate }}</template>
      </el-table-column>
      <el-table-column
        :label="isRecharge ? $t('tableI18n.DepositAmountDollars') : $t('tableI18n.WithdrawAmountDollars')"
        prop="amount"
        align="center"
        width="120"
      >
        <template v-slot="scope">{{ scope.row.amount | toThousandFilterTwo }}</template>
      </el-table-column>

        <el-table-column v-if="isRecharge" :label="$t('tableI18n.RealAmountDollars')" prop="amount" align="center" width="120">
            <template v-slot="scope">
                {{ scope.row.actualAmount | toThousandFilterTwo }}
            </template>
        </el-table-column>
<!--
        :label="$t('tableI18n.TransactionRemarkCode')"
        prop="transferCode"-->
<!--      <el-table-column-->
<!--        v-if="isRecharge"-->
<!--        :label="$t('tableI18n.TransactionCode')"-->
<!--        prop="transationCode"-->
<!--        align="center"-->
<!--        width="110"-->
<!--      ></el-table-column>-->
      <el-table-column
        :label="isRecharge ? $t('tableI18n.TransactionBanking') : $t('tableI18n.ReceivingBank')"
        prop="receivingBank"
        align="center"
        width="110"
      ></el-table-column>
      <el-table-column
        :label="$t('tableI18n.BankAccountNumber')"
        prop="bankAccountNumber"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        :label="$t('tableI18n.AccountHolderName')"
        prop="accountHolderName"
        align="center"
        width="100"
      ></el-table-column>
        <el-table-column v-if="isRecharge" :label="$t('tableI18n.TransactionRemarkCode')" prop="transferCode" align="center" width="120"></el-table-column>
        <el-table-column :label="$t('tableI18n.swiftCode')" prop="swiftCode" align="center" width="120"></el-table-column>
      <el-table-column :label="$t('tableI18n.Status')" prop="status" align="center" width="90">
        <template v-slot="scope">{{ scope.row.status | processFormat }}</template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.ReviewedF')" prop="auditLogOne.adminUsername" align="center">
      </el-table-column>
      <el-table-column :label="$t('tableI18n.ReviewedFT')" prop="auditLogOne.createdAt" align="center" width="200">
        <template v-slot="scope">{{ scope.row.auditLogOne && scope.row.auditLogOne.createdAt }}</template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.ReviewedL')" prop="auditLogTwo.adminUsername" align="center">
      </el-table-column>
      <el-table-column :label="$t('tableI18n.ReviewedLT')" prop="auditLogTwo.createdAt" align="center" width="200">
        <template v-slot="scope">{{ scope.row.auditLogTwo && scope.row.auditLogTwo.createdAt }}</template>
      </el-table-column>
        <el-table-column :label="$t('tableI18n.Notes')" prop="remard" show-overflow-tooltip align="center">
        </el-table-column>
      <!--       :fixed="'right'"-->
      <el-table-column
        :label="$t('tableI18n.Operation')"
        align="center" fixed="right"
        :width="language === 'zh-cn' ? '170' : '220'"
        :show-overflow-tooltip="true"
      >
        <template v-slot="scope">
          <!-- 初审复审、充值提现区别 -->
          <el-button
            v-if="scope.row.status === Number(process.SECOND.valueNew)"
            size="mini"
            class="btn-black"
            @click="showDialogs(scope.row);"
          >
            <span>{{ $t("btnI18n.Approve") }}</span>
          </el-button>
          <el-button
            v-if="scope.row.status === Number(process.SECOND.valueNew)"
            size="mini"
            class="btn-black"
            @click="rowData = scope.row;params.description = '';dialogReject = true;"
          >{{ $t("btnI18n.Reject") }}</el-button>
          <!-- 初审复审、充值提现区别 -->
          <div
            v-if="scope.row.status === Number(category.RECHARGE.valueNew) && scope.row.status === Number(process.CONFIRM.valueNew)"
          >
            <div>{{ $t("columnI18n.ApprovalDate") }}</div>
            <div>{{ scope.row.completeDate | timeStampToDate }}</div>
          </div>
          <div
            v-if="scope.row.status === Number(category.WITHDRAW.valueNew) && scope.row.status === Number(process.CONFIRM.valueNew)"
          >
<!--            <div>{{ $t("columnI18n.TransactionRemarkCode") }}</div>-->
<!--            <div>{{ scope.row.transferCode }}</div>-->
            <div>{{ $t("columnI18n.TransactionCode") }}</div>
            <div>{{ scope.row.transationCode }}</div>
          </div>
          <div v-if="scope.row.status === Number(process.REJECT.valueNew)">
            <div>{{ $t("columnI18n.ResonsforRejection") }}</div>
            <div>{{ scope.row.remard }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
      <el-dialog
              :close-on-click-modal="false"
              :title="$t('dialogTitleI18n.RechargeConfirm')"
              :visible.sync="dialogRecharge"
              width="50%">
          <div class="text-form">
              <div class="text-title">{{ $t('dialogCtI18n.TipsRechargeConfirmNew1', {money: formatAmount(rowData.amount)}) }}</div>
              <div class="flex-base text-form-item">
                  <div class="text-column">{{ $t('dialogCtI18n.TipsRechargeConfirmNew2') }}</div>
                  <el-input v-model="params.rechargeAmount" style="width: 80px;"></el-input>
                  <div style="padding-left: 1rem;">({{ $t('millionDollars') }})</div>
              </div>
              <div class="flex-base text-form-item-sub">
                  <div class="text-column"></div>
                  <div class="text-tips">({{ $t('dialogCtI18n.TipsRechargeConfirmNew3', {money1: formatAmount(rowData.amount), money2: formatAmount(params.rechargeAmount)}) }})</div>
              </div>
              <div class="flex-base text-form-item">
                  <div class="text-column" style="align-self: baseline;">{{ $t('dialogCtI18n.TipsRechargeConfirmNew4') }}</div>
                  <el-input v-model="params.rechargeRemark" type="textarea" rows="4" style="width: calc(100% - 10rem);"></el-input>
              </div>
              <div class="dialog-btn flex-row-reverse">
                  <el-button class="btn-black" @click="updateRowStatus()" :disabled="isSubmit">{{ $t('btnI18n.Confirms') }}</el-button>
                  <el-button class="btn-cancel" @click="dialogRecharge = false;" :disabled="isSubmit">{{ $t('btnI18n.Cancel') }}</el-button>
              </div>
          </div>
      </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dialogTitleI18n.Withdrawal')"
      :visible.sync="dialogWithdrawal"
      @close="closeWithdrawal"
      width="50%"
    >
      <div class="text-center flex-column-center">
        <div>
          <el-image class="ct-img" :src="imagesSrc[1]"></el-image>
        </div>
        <div class="text-left">
          <div class="form-required"></div>
          {{ $t('dialogCtI18n.TipsWithdrawalConfirm1', {money: formatAmount(rowData.amount)}) }}
        </div>
        <div
          v-if="language !== 'zh-cn'"
          class="text-left"
        >{{ $t('dialogCtI18n.TipsWithdrawalConfirm2') }}</div>
        <div class="text-left">
<!--          <el-input v-model="params.transferCode"></el-input>-->
          <el-input v-model="params.transationCode"></el-input>
        </div>
        <div class="dialog-btn">
          <el-button
            class="btn-black"
            @click="updateRowStatus()"
            :disabled="isSubmit"
          >{{ $t('btnI18n.Confirms') }}</el-button>
          <el-button
            class="btn-black"
            @click="closeWithdrawal"
            :disabled="isSubmit"
          >{{ $t('btnI18n.Cancel') }}</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('dialogTitleI18n.Reject')"
      :visible.sync="dialogReject"
      @close="closeReject"
      width="50%"
    >
      <div class="text-center flex-column-center">
        <div>
          <el-image class="ct-img" :src="imagesSrc[2]"></el-image>
        </div>
        <div class="text-left">
          <div class="form-required"></div>
          {{ $t('dialogCtI18n.TipsRejectInput1') }}
        </div>
        <div class="text-left">
          <el-input v-model="params.reason"></el-input>
        </div>
        <div class="dialog-btn">
          <el-button
            class="btn-black"
            @click="updateRowStatus(9)"
            :disabled="isSubmit"
          >{{ $t('btnI18n.Confirms') }}</el-button>
          <el-button
            class="btn-black"
            @click="closeReject"
            :disabled="isSubmit"
          >{{ $t('btnI18n.Cancel') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiApproveExchangeRequest, apiRejectExchangeRequest } from "@/utils/api";
import filter from "@/filters";
import AdPagination from "@/components/ad-pagination";

export default {
  name: "fm-second",
  components: {
    AdPagination
  },
  data() {
    return {
      imagesSrc: [require(`@/static/img/dialog-recharge.png`), require(`@/static/img/dialog-withdraw.png`), require(`@/static/img/dialog-reject.jpg`)],
      isSubmit: false,
      isSupplier: false,
      category: this.$enums.CATEGORY,
      process: this.$enums.PROCESS,
      selectProcess: "",
      selectOption: [
        { label: "全部", value: "ALL" },
        { label: "待审核", value: "FIRST" },
        { label: "通过", value: "CONFIRM" },
        { label: "驳回", value: "REJECT" },
      ],
      isRecharge: true,
      changeBtn: 1,
      dialogRecharge: false,
      dialogWithdrawal: false,
      dialogReject: false,
      requestUrl: "",
      processUrl: "",
      rejectUrl: "",
      countUrl: "",
      rechargeNotApproveNum: 0,
      withdrawNotApproveNum: 0,
      countInfo: {
        rechargeNotApproveNum: 0,
        withdrawNotApproveNum: 0,
      },
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      dataParams: {
        category: "",
        process: "",
        noProcess: "",
      },
      dataList: [],
      rowData: {},
      params: {
        // transferCode: "",
        transationCode: "",
        reason: "",
        id: "",
        rechargeAmount: 0,
        rechargeRemark: ""
      },
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
    };
  },
  mounted() {
    // 判断是否是供应商，隐藏掉充值按钮
    this.isSupplier = this.$route.path.includes("supplier");
    this.selectProcess = this.selectOption[0].value;
    // 充值筛选按钮
    this.isRecharge = !this.isSupplier;
    this.getDataList();
    this.getCount();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getDataList();
    },
    formatAmount(value) {
      return filter.numberFormat(value);
    },
    // 获取统计条数
    getCount() {
      // 查询数据参数，是初审还是复审
      let _this = this;
      this.$axios.get("/manage-user-account/orders", {        params: {
          page: this.tablePageCP,
          pageSize: this.tablePagePS,
          role: this.isSupplier ? "1" : "2",
          type: "1",
        }      }).then(res => {
        if (!res.code) {
          _this.rechargeNotApproveNum = res.data.rechargeCount;
        }
      });
      this.$axios.get("/manage-user-account/orders", {        params: {
          page: this.tablePageCP,
          pageSize: this.tablePagePS,
          role: this.isSupplier ? "1" : "2",
          type: "2"
        }      }).then(res => {
        if (!res.code) {
          _this.withdrawNotApproveNum = res.data.withdrawCount;
        }
      });
    },
    // 获取列表数据
    getDataList(val, b) {
      // 如果筛选数据，点击的是同一个按钮，那么直接return不会调用任何方法
      // 如果筛选数据，点击的是同一个按钮，那么直接return不会调用任何方法
      if (val && this.changeBtn === val) {
        return false;
      }
      // 如果b不为空的时候，代表为筛选数据，
      if (b) {
        this.isRecharge = (b === "true");
        this.selectProcess = this.selectOption[0].value;
      }
      // 解决从其他路由跳转的时候，初始化页面，但是当前页面对应的不同路由不会走此块逻辑
      // 记录按钮状态
      this.changeBtn = this.isRecharge ? 1 : 2;
      this.tableLoading = true;

      // role: 1 supplier 2 invest
      let params = {
        page: this.tablePageCP,
        pageSize: this.tablePagePS,
        role: this.isSupplier ? "1" : "2",
        type: this.isRecharge ? "1" : "2",
      };
      let _this = this;
      this.$axios.get("/manage-user-account/orders", { params: params }).then(res => {
        this.tableLoading = false;
        if (!res.code) {
          _this.dataList = res.data.rows;
          _this.tableTotal = res.data.count || 0;
        }
      }).catch(err => {
        console.log(err);
        this.tableLoading = false;
      });
      this.tableEmpty = this.$t('tableEmpty');
    },
    showDialogs(row) {
      this.rowData = row;
      // 判断展示充值或者提现的弹框
      if (this.isRecharge) {
        this.dialogRecharge = true;
      } else {
        this.dialogWithdrawal = true;
      }
    },
    async updateRowStatus(stat) {
      /*充值：小于5M无需提交复审，大于3000w提交复审展示
      提现：复审
      状态为9拒绝，或者不为9的时候动态赋值*/
      if (!stat) {
        stat = this.rowData.status === Number(this.process.SECOND.valueNew) ? 1 : 9;
      }
      // 参数组装：修改审核状态
      let params = {
        id: this.rowData.id
      };
      // 提现的时候填写交易流水号，只有复审审填写
      if (!this.isRecharge && stat === 1 && (!this.params.transationCode || this.params.transationCode === "")) {
        this.$message.error(this.$t('submittedFail'));
        return false;
      }
      // 拒绝的时候填写拒绝原因
      if (stat === 9 && (!this.params.reason || this.params.reason === "")) {
        this.$message.error(this.$t('submittedFail'));
        return false;
      }
      try {
        this.isSubmit = true;
        let url = "";
        // 合约接口只有拒绝的时候需要填写原因，其余的时候只需要传合约id即可
        if (stat === 9) {
          url = "/manage-user-account/reject";
          Object.assign(params, { reason: this.params.reason });
        } else {
          url = "/manage-user-account/approve";
          if (this.params.transationCode) {
              Object.assign(params, {transationCode: this.params.transationCode});
          }
          if (this.params.rechargeAmount) {
              Object.assign(params, {actualAmount: this.params.rechargeAmount});
          }
          if (this.params.rechargeRemark) {
              Object.assign(params, {remard: this.$t('dialogCtI18n.TipsRechargeConfirmNew3', {money1: this.formatAmount(this.rowData.amount), money2: this.formatAmount(this.params.rechargeAmount)}) + this.params.rechargeRemark});
          }
        }
        this.approveData(url, params);
      } catch (e) {
        console.log(e);
        this.isSubmit = false;
      }
      this.dialogRecharge = false;
      this.dialogWithdrawal = false;
      this.dialogReject = false;
    },
    // 关闭提现弹窗
    closeWithdrawal() {
      this.dialogWithdrawal = false;
      this.params.transationCode = '';
    },
    // 关闭驳回弹窗
    closeReject() {
      this.dialogReject = false;
      this.params.reason = '';
    },
    approveData(url, params) {
      this.$axios.post(url, params).then(res => {
        console.log(res);
        this.isSubmit = false;
        this.getDataList();
        this.getCount();
        this.params.transationCode = '';
        this.params.reason = '';
      }).catch(err => {
        console.log(err);
        this.isSubmit = false;
        this.params.transationCode = '';
        this.params.reason = '';
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    },
    $route(to, from) {
      this.countInfo = {};
      // 判断是否是供应商，隐藏掉充值按钮
      this.isSupplier = this.$route.path.includes("supplier");
      this.selectProcess = this.selectOption[0].value;
      // 充值筛选按钮
      this.isRecharge = !this.isSupplier;
      this.getDataList();
      this.getCount();
    }
  }
};
</script>

<style scoped lang="scss">
.search-select {
  margin-left: 20px;
}
.change-rw {
  margin-bottom: 20px;
}
.table-data {
  margin-top: 20px;
}
.tips-count-num {
  background-color: #80d3cb;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  &:before {
    color: #fff;
    background: #80d3cb;
  }
}
.text-center {
  text-align: center;
  .text-left {
    text-align: left;
    width: 70%;
    margin: 5px 0;
  }
  .ct-img {
    width: 96px;
    height: 96px;
    margin-bottom: 21px;
  }
  .dialog-btn {
    margin-top: 44px;
  }
}
.text-form {
  .text-form-item {
    margin-top: 1rem;
  }
  .text-form-item-sub {
    margin-top: 0.5rem;
  }
  .text-title {
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #353535;
    line-height: 22px;
  }
  .text-column {
    width: 7rem;
    padding-left: 2rem;
    text-align: left;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #6C6C6C;
    line-height: 20px;
  }
  .text-tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6C6C6C;
    line-height: 18px;
  }
  .dialog-btn {
    margin-top: 44px;
  }
  .btn-cancel {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
